import React, { Component } from 'react'
import Slider from 'react-slick';
import { v4 } from 'uuid'
import Image from './Image'
import { HTMLContent } from '../components/Content'
import BackgroundTriangleAndTitle from '../components/BackgroundTriangleAndTitle'
import VisibilitySensor from "react-visibility-sensor";
import { animateStyles } from '../utils'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class DualSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageRef: null,
      textRef: null
    };
  }

  componentDidMount() {
    this.setState({
      imageRef: this.imageSlider,
      textRef: this.textSlider
    });
  }

  render() {
    const imageConfig = {
      autoplay: true,
      autoplaySpeed: 4000,
      dots: true,
      dotsClass: "slick-dots slider-text-dots",
      fade: false,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
    };

    const textConfig = {
      dotsClass: "slick-dots slider-text-dots",
      fade: true,
      infinite: true,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
    }

    return (
      <VisibilitySensor partialVisibility>
        {({ isVisible }) => (
          <div className="content slider-image-section" style={isVisible ? animateStyles['fadeIn'].finish : animateStyles['fadeIn'].start}>
        {/* <BackgroundTriangleAndTitle position={ this.props.triangle } title="" /> */}
        <div className="columns">
          
          <div className="column is-12">
            <h2>{ this.props.slides.dualsliderTitle }</h2>
            <hr className="hr-black"></hr>
          </div>
        </div>
        <div className="columns">
          
          <Slider
            {...imageConfig}
            className="column is-6"
            ref={slider => (this.imageSlider = slider)}
            asNavFor={this.state.textRef}
          >
            {
              this.props.slides.slides.map((image) => (
                <Image key={v4()} imageInfo={{ image: image.image }} className="slider-text-image" />
              ))
            }
          </Slider>
          <Slider
            {...textConfig}
            className="column is-6"
            ref={slider => (this.textSlider = slider)}
            asNavFor={ this.state.imageRef }
          >
            {
              this.props.slides.slides.map((image) => (
                <div key={v4()} className="slider-text-content">
                  <h4>{image.title}</h4>
                  <hr className="hr-black"></hr>
                  <HTMLContent content={image.text} />
                </div>
              ))
            }
          </Slider>
          
        </div>
      </div>
        )}
      </VisibilitySensor>
    );
  }


}
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import BackgroundImage from './BackgroundImage';
import LinkComponent from '../components/LinkComponent';
import { ImageOverlay } from '../utils';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const PortfolioTilesComponent = ({ settings, locale }) => {
  const tiles = settings.portfolioTiles;
  const getLocaleUrlString = (link) => {
    switch (locale) {
      case 'en':
        return link;
      case 'jp':
        return `jp/${link}`;
      case 'zhhans':
        return `zh-hans/${link}`;
    }
  };
  return (
    <div>
      <h2 className='title services-title'>
        {tiles.portfolioTilesTitle[locale]}
      </h2>
      <hr className='hr-black'></hr>
      <div className='columns is-4'>
        <div className='column is-4'>
          <LinkComponent
            linkObject={{
              link: getLocaleUrlString(tiles.tile1.linkObject.link),
              isExternal: tiles.tile1.linkObject.isExternal,
            }}>
            <BackgroundImage
              classes={'featured-background-image hover-scale'}
              imageInfo={tiles.tile1.image}>
              <div className='home-text home-text-white portfolio-text-title'>
                {tiles.tile1.title[locale]}
                <hr className='hr-white' />
              </div>
              <ImageOverlay overlay={tiles.tile1.overlay} />
            </BackgroundImage>
          </LinkComponent>
        </div>
        <div className='column is-4'>
          <LinkComponent
            linkObject={{
              link: getLocaleUrlString(tiles.tile2.linkObject.link),
              isExternal: tiles.tile2.linkObject.isExternal,
            }}>
            <BackgroundImage
              classes={'featured-background-image hover-scale'}
              imageInfo={tiles.tile2.image}>
              <div className='home-text home-text-white portfolio-text-title'>
                {tiles.tile2.title[locale]}
                <hr className='hr-white' />
              </div>
              <ImageOverlay overlay={tiles.tile2.overlay} />
            </BackgroundImage>
          </LinkComponent>
        </div>
        <div className='column is-4'>
          <LinkComponent
            linkObject={{
              link: getLocaleUrlString(tiles.tile3.linkObject.link),
              isExternal: tiles.tile3.linkObject.isExternal,
            }}>
            <BackgroundImage
              classes={'featured-background-image hover-scale'}
              imageInfo={tiles.tile3.image}>
              <div className='home-text home-text-white portfolio-text-title'>
                {tiles.tile3.title[locale]}
                <hr className='hr-white' />
              </div>
              <ImageOverlay overlay={tiles.tile3.overlay} />
            </BackgroundImage>
          </LinkComponent>
        </div>
      </div>
    </div>
  );
};

export default ({ locale, isPreview }) => {
  if (isPreview) {
    return (
      <h2>
        You can edit the Our Portfolio Tiles under Settings / Portfolio Tiles
      </h2>
    );
  }
  return (
    <StaticQuery
      query={graphql`
        query portfolioTiles {
          markdownRemark(
            frontmatter: { templateKey: { eq: "portfolio-tiles" } }
          ) {
            frontmatter {
              portfolioTiles {
                portfolioTilesTitle {
                  en
                  jp
                  zhhans
                }
                tile1 {
                  title {
                    en
                    jp
                    zhhans
                  }
                  image
                  overlay
                  linkObject {
                    link
                    isExternal
                  }
                }
                tile2 {
                  title {
                    en
                    jp
                    zhhans
                  }
                  image
                  overlay
                  linkObject {
                    link
                    isExternal
                  }
                }
                tile3 {
                  title {
                    en
                    jp
                    zhhans
                  }
                  image
                  overlay
                  linkObject {
                    link
                    isExternal
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <PortfolioTilesComponent
          settings={data.markdownRemark.frontmatter}
          locale={locale}
        />
      )}
    />
  );
};
